.button {
  border-radius: 20px;
  cursor: pointer;
  padding: 18px 50px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  content: " ";
  box-shadow: none;
  filter: drop-shadow(14.3386px 19.3489px 27.1564px rgba(0,0,0,.15));
  transition-property: box-shadow, filter;
  transition-duration: .5s;
  transition-timing-function: linear;
}

.button_type_about {
  padding: 0;
  background: none;
  color: white;
  text-decoration: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  margin-right: 30px;
}

.button_type_product {
  width: 100%;
  background-color: #506C7F;
  color: white;
}

.button_type_order {
  color: white;
  background-color: #506C7F;
  display: flex;
  align-items: center;
  gap: 8px;
}

.button_type_order:hover {
  box-shadow: inset -3.056px -6.876px 5.348px rgb(0 0 0 / 15%);
  filter: drop-shadow(14.3386px 19.3489px 27.1564px rgba(0,0,0,.35));
}

.button_type_examples {
  background-color: #506C7F;
  color: white;
}

.button_type_submit {
  width: 200px;
  height: 60px;
  background-color: #506C7F;
  color: white;
  border-radius: 20px;
  padding: 0;
  margin-top: 28px;
}

.anchor-scroll {
  text-decoration: none;
}