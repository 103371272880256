.footer {
  background: #2D2D2D;
}

.footer__text  {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'liga' off;
  color: #FFFFFF;
  margin: 0;
  padding: 0;
  width: 100%;
}

.footer__container  {
  width: 1160px;
  margin: auto;
  padding: 24px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer__contacts {
  width: 100%;
  list-style: none;
  color: #d2d2d2;
  padding: 0;
}