.lead {
  max-width: 1280px;
  margin: auto;
  padding: 120px 55px 0px;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-size: cover;
  z-index: 1;
  box-sizing: content-box;
  width: 100%;
}

.lead::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.4);
}

.lead__visuals {
  position: relative;
  width: 450px;
  height: 450px;
}

.lead__text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  margin: 0;
  color: #FFE;
}

.lead__slogan {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 47px;
  line-height: 130%;
  letter-spacing: -0.025em;
  margin: 0;
  color: white;
}

.lead__heading {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 648px;
}

.lead__container {
  display: flex;
  gap: 72px;
  align-items: flex-start;
}

.lead__buttons {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 70px 0 0;
  gap: 18px;
}