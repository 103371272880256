.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  margin: auto;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.60) 0%, rgba(255, 255, 255, 0.10) 100%);
  box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
  backdrop-filter: blur(10px);
  z-index: 3;
  display: flex;
  flex-direction: column;
}

.modal-button {
  width: 280px;
  align-self: center;
}