.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 55px;
  gap: 60px;
  max-width: 1280px;
  margin: auto;
}

.about__cards {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.about__info {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 20px;
}

.about__text {
  max-width: 800px;
  font-size: 18px;
  text-align: center;
}

