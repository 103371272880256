.preloader {
  width: 100%;
  height: 100%;
  position: fixed;

}

.preloader__container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.preloader__image {
  width: 5%;
  height: fit-content;
  animation: animatePreloader 3s infinite;
}

@keyframes animatePreloader {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 1;
    transform: rotate(360deg);
  }

  to{
    opacity: 0;
    transform: rotate(360deg);
  }
}