.product-details {
  display: flex;
  flex-direction: column;
}

.product-details__content {
  display: flex;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  letter-spacing: normal;
  gap: 50px;
  width: 90%;
  margin: auto;
}

.product-details__title {
  color: white;
}

.product-details__subtitle {
  color: #d4d4d4;
}

.product-details__picture {
  width: 150px;
}

.product-details__text {
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 90%;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  flex-wrap: wrap;
  gap: 30px;
  overflow-y: auto;
  max-height: 400px;
  max-width: 1440px;
}

.product-details__text_type_row {
  flex-direction: row;
}



.product-details__card-name {
  align-items: center;
  justify-content: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: white;
  text-align: center;
}

.product-details__card-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: normal;
  color: #d4d4d4; 
}

.product-details__card-text_type_row {
  text-align: justify;
  text-indent: 30px;
}

.product-details__card {
  max-width: 400px;
}

.product-details__card_type_row {
  max-width: 100%;
}