.products {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 80px 55px;
  position: relative;
  z-index: 1;
  max-width: 1280px;
  margin: auto;
}

.products::before {
  background: inherit;
  box-shadow: inset 0 0 0 3000px rgba(0, 0, 0, 0.0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: -1;
}

.products__cards {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}