@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url('./Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url('./Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url('./Montserrat-SemiBold.ttf');
}
