.app {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  transition: opacity .5s ease-in;
  opacity: 0;
}

.app_visible {
  opacity: 1;
}