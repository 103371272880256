.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo__image {
  background-image: url('../../media/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 75px;
  height: 75px;
}

.logo__name {
  margin: 0 0 0 6px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  color: white;
}