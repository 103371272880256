.cover {
  padding: 60px 55px;
  max-width: 1280px;
  margin: auto;
}

.cover__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding-top: 60px;
  gap: 40px;
  z-index: 1;
  background-image: url('../../media/chip.jpeg');
  background-size: 120%;
  background-position: 68% 25%;
  background-repeat: no-repeat;

}

.cover__container::before {
  position: absolute;
  border-radius: 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2b2c33;
  opacity: 0.8;
  content: '';
  z-index: -1;
}

.cover__visuals {
  position: relative;
  width: 100%;
  height: 211px;
  overflow: hidden;
}

.cover__text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  text-align: center;
  color: #ffe;
  margin: 0;
}

.cover__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.cover__heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: white;
  margin: 0;
}