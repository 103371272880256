@import url("./vendor/normalize.css");
@import url("./vendor/fonts/fonts.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin: 0;
  position: relative;
  color: black;
}

#root::after {
  background-image: url('./media/page-back.jpg');
  background-color: white;
  opacity: 0.85;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -3;
  content: '';
}

/* Миксы */

.section-heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.05em;
  color: white;
  margin: 0;
}

.section-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: white;
  margin: 0;
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background: lightgray;
  border-radius: 10px;
}

::-webkit-scrollbar-corner{
  border-radius: 10px;
}