.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
  box-shadow: 5px 5px 4px 0px rgba(255, 255, 255, 0.08) inset, -5px -5px 4px 0px rgba(255, 255, 255, 0.04) inset;
  backdrop-filter: blur(10px);
}
