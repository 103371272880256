.about-details {
  display: flex;
  flex-direction: column;
}

.about-details__content {
  display: flex;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 150%;
  letter-spacing: normal;
  gap: 50px;
  width: 90%;
  margin: auto;
}

.about-details__title {
  color: white;
}

.about-details__subtitle {
  color: #d4d4d4;
}

.about-details__picture {
  width: 150px;
}

.about-details__text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
  width: 90%;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  gap: 30px;
  overflow-y: scroll;
  max-height: 400px;
  padding: 0 20px;
}

.about-details__card-name {
  align-items: center;
  justify-content: center;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: white;
  text-align: center;
}

.about-details__card-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: normal;
  color: #d4d4d4; 
}

.about-details__card {
  width: 400px;
}

.about-details__card:first-child {
  text-indent: 30px;
  width: 100%;
  text-align: justify;
  color: #d4d4d4;
}

.about-details__card:not(:first-child) {
  flex: 1;
}
